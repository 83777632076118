.simple-modal
    display: flex
    align-items: flex-start
    justify-content: center
    position: fixed
    inset: 0
    background-color: rgba(0, 0, 0, .4)
    overflow-x: hidden
    overflow-y: auto
    outline: 0
    z-index: 1060
    opacity: 0
    transition: opacity .4s ease-in-out
    pointer-events: none
    padding: 16px 0

    &.active
        pointer-events: all
        opacity: 1

    &.enter-done, &.show
        opacity: 1
        pointer-events: visible

    &.exit
        opacity: 0

    &__content
        margin-top: auto
        margin-bottom: auto
        width: 564px
        max-width: 80vw
        padding: 32px
        box-sizing: border-box
        background-color: white
        border-radius: 8px
        box-shadow: $dropshadow
        transform: translateY(-100px)
        transition: all .2s ease-in-out .1s
        opacity: 0
        position: relative
        &--review-data
            transform: none
        &--sm
            width: 464px
        &--md
            width: 810px
        &--lg
            width: 900px
        &--full
            width: 100%
        &--p-md
            @media (min-width: $md)
                padding: 40px
        &--br-md
            border-radius: 12px
        &--bright
            background-color: var(--color-bright)

        .simple-modal.enter-done &, .simple-modal.show &
            transform: translateY(0)
            opacity: 1

        .simple-modal.exit &
            transform: translateY(-100px)
            opacity: 0

        > .card__icon-wrapper--sm
            position: absolute
            right: 12px
            top: 12px

    &__header, &__body
        margin-bottom: 40px

    &__header
        background: var(--color-primary-dark)
        box-shadow: $dropshadow
        border-radius: 4px
        padding: 36px
        color: white
        text-align: center

    &__simple-header
        display: flex
        align-items: center
        gap: 12px
        margin-bottom: 24px

    &__title
        color: var(--color-primary-dark)
        border-bottom: 1px solid var(--color-bright)
        padding-bottom: 4px
        font-weight: 700
        font-size: 1.6rem
        margin-bottom: 1em

        &--with-btn
            flex-grow: 1
            border-bottom: 0
            margin-bottom: 0
            padding-bottom: 0

    &__dialog-title
        color: var(--color-primary-dark)
        font-size: 1.6rem
        font-weight: 700
        text-align: center
        margin-bottom: 20px

    &__close-btn
        @extend .accessed-materials-widget__btn

    &__teacher
        margin-bottom: 0

    &__test
        color: var(--color-primary-lighter)
        display: block
        width: fit-content
        padding: 8px 24px
        border: 1px solid var(--color-neutral-lighter)
        line-height: 1
        border-radius: 4px
        margin: 0 auto 40px auto

    &__description
        width: 80%
        margin: 0 auto
        display: flex
        align-items: center
        gap: 16px

        &--cpf
            flex-direction: column
            padding-top: 20px

    &__input
        width: 100%

    &__progress
        flex: 0 0 auto

    &__description-text
        margin-bottom: 0

    &__footer
        text-align: center

    &__reminder
        color: var(--color-neutral)
        margin-bottom: 0
        font-size: 1.2rem

        &--finalize
            font-size: 1.6rem
            margin-bottom: 1.6rem
            color: black

    &__btns
        display: flex
        align-items: center
        flex-wrap: wrap
        justify-content: center
        gap: 12px
        width: 80%
        margin: 0 auto

        .btn
            flex-grow: 1
            justify-content: center

    // Schedule
    &__img-schedule
        display: block
        width: 100%
        height: 176px
        object-fit: cover
        border-radius: 8px
        margin-bottom: 28px

    &__title-schedule
        font-weight: 700
        font-size: 1.6rem
        line-height: 1.5
        color: var(--color-primary-dark)
        margin-bottom: 0

    &__date-schedule
        color: var(--color-neutral-dark)
        font-weight: 700
        font-size: 1.6rem
        margin-bottom: 0

    &__hour-schedule
        color: var(--color-neutral-dark)
        font-weight: 300
        font-size: 1.4rem
        margin-bottom: 0

    &__text-schedule
        color: var(--color-neutral-dark)
        font-weight: 300
        font-size: 1.4rem
        margin: 20px 0
        white-space: pre-line
